  .SlideUp-appear {
      opacity: 0;
    }
    
  .SlideUp-appear.SlideUp-appear-active {
    opacity: 1;
    transition: all 0.7s linear;
  }
  .SlideUp-enter {
      transform: translateY(600px);
    -webkit-transform: translateY(600px);
    -moz-transform: translateY(600px);
    -o-transform: translateY(600px);
    -ms-transform: translateY(600px);
    opacity: 0;
  }
  .SlideUp-enter.SlideUp-enter-active {
      opacity: 1;
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -o-transform: translateY(0);
      -ms-transform: translateY(0);
      transition: all 0.7s linear 0.1s;
  }
  .SlideUp-leave {
      opacity: 1.0;
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -o-transform: translateY(0);
      -ms-transform: translateY(0);
  }
  .SlideUp-leave.SlideUp-leave-active {
      opacity: 0;
      transform: translateY(-600px);
      -webkit-transform: translateY(-600px);
      -moz-transform: translateY(-600px);
      -o-transform: translateY(-600px);
      -ms-transform: translateY(-600px);
      transition: all 0.7s linear;
  }

  
  .SlideDown-appear {
    transform: translateX(-600px);
    -webkit-transform: translateX(-600px);
    -moz-transform: translateX(-600px);
    -o-transform: translateX(-600px);
    -ms-transform: translateX(-600px);
    opacity: 0;
  }
  
  .SlideDown-appear.SlideDown-appear-active {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transition: all 0.7s linear 0.1s;
  }
  .SlideDown-enter {
      opacity: 0;
      transform: translateY(-600px);
    -webkit-transform: translateY(-600px);
    -moz-transform: translateY(-600px);
    -o-transform: translateY(-600px);
    -ms-transform: translateY(-600px);
  }
  .SlideDown-enter.SlideDown-enter-active {
      opacity: 1;
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -o-transform: translateY(0);
      -ms-transform: translateX(0);
      transition: all 0.7s linear 0.1s;
  }
  .SlideDown-leave {
      opacity: 1.0;
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -o-transform: translateY(0);
      -ms-transform: translateY(0);
  }
  .SlideDown-leave.SlideDown-leave-active {
      opacity: 0;
      transform: translateY(600px);
      -webkit-transform: translateY(600px);
      -moz-transform: translateY(600px);
      -o-transform: translateY(600px);
      -ms-transform: translateY(600px);
      transition: all 0.7s linear;
  }

  .SlideStay-enter {
    opacity: 0;
    /* transform: translateY(-600px);
  -webkit-transform: translateY(-600px);
  -moz-transform: translateY(-600px);
  -o-transform: translateY(-600px);
  -ms-transform: translateY(-600px); */
}
.SlideStay-enter.SlideStay-enter-active {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateX(0);
    transition: all 0.7s linear 0.1s;
}
/* customer feedback css */

.feedback-enter {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 500ms;
}
.feedback-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 500ms;
}
.feedback-exit {
  opacity: 1;
  transform: translateX(0%);
}
.feedback-exit-active {
  opacity: 0;
  transform: translateX(100%);
}
.feedback-enter-active,
.feedback-exit-active {
  transition: opacity 1000ms, transform 1000ms;
}

/* legacy updated */

.slide-up-enter {
  transform: translateY(400px);
-webkit-transform: translateY(400px);
-moz-transform: translateY(400px);
-o-transform: translateY(400px);
-ms-transform: translateY(400px);
opacity: 0;
}
.slide-up-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 500ms;
}
.slide-up-exit {
  opacity: 1.0;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
}
.slide-up-exit-active {
  opacity: 0;
  transform: translateY(-100px);
  -webkit-transform: translateY(-100px);
  -moz-transform: translateY(-100px);
  -o-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transition: all 0.7s linear;
}
.slide-up-enter-active,
.slide-up-exit-active {
  transition: opacity 1000ms, transform 1000ms;
}

/* down */

.slide-down-enter {
  transform: translateY(-100px);
-webkit-transform: translateY(-100px);
-moz-transform: translateY(-100px);
-o-transform: translateY(-100px);
-ms-transform: translateY(-100px);
opacity: 0;
}
.slide-down-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 500ms;
}
.slide-down-exit {
  opacity: 1.0;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
}
.slide-down-exit-active {
  opacity: 0;
  transform: translateY(400px);
  -webkit-transform: translateY(400px);
  -moz-transform: translateY(400px);
  -o-transform: translateY(400px);
  -ms-transform: translateY(400px);
  transition: all 0.7s linear;
}
.slide-down-enter-active,
.slide-down-exit-active {
  transition: opacity 1000ms, transform 1000ms;
}
/* to do list */

/* right */
.feedback-right-enter {
  opacity: 0;
  transform: translateX(300px);
  transition: all 500ms;
}
.feedback-right-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 500ms;
}
.feedback-right-exit {
  opacity: 1;
  transform: translateX(0%);
}
.feedback-right-exit-active {
  opacity: 0;
  transform: translateX(-300px);
}
.feedback-right-enter-active,
.feedback-right-exit-active {
  transition: opacity 1000ms, transform 1000ms;
}

/* left */
.feedback-left-enter {
  opacity: 0;
  transform: translateX(-300px);
  transition: all 500ms;
}
.feedback-left-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 500ms;
}
.feedback-left-exit {
  opacity: 1;
  transform: translateX(0%);
}
.feedback-left-exit-active {
  opacity: 0;
  transform: translateX(300px);
}
.feedback-left-enter-active,
.feedback-left-exit-active {
  transition: opacity 1000ms, transform 1000ms;
}

/* inner slides */
.slide-inner-enter {
  transform: translateY(400px);
-webkit-transform: translateY(400px);
-moz-transform: translateY(400px);
-o-transform: translateY(400px);
-ms-transform: translateY(400px);
opacity: 0;
}
.slide-inner-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 500ms;
}
.slide-inner-exit {
  opacity: 1.0;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
}
.slide-inner-exit-active {
  opacity: 0;
  transform: translateY(-100px);
  -webkit-transform: translateY(-100px);
  -moz-transform: translateY(-100px);
  -o-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transition: all 0.7s linear;
}
.slide-inner-enter-active,
.slide-inner-exit-active {
  transition: opacity 1000ms, transform 1000ms;
}

/* try css only */

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
