.slide-back-enter {
  transform: translateX(-100%);
}

.slide-back-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
}

.slide-back-exit {
  transform: translateX(0);
}

.slide-back-exit-active {
  transform: translateX(100%);
  transition: transform 300ms;
}

.slide-back-appear {
  opacity: 0;
  transform: translateY(20px);
}

.slide-back-appear-active {
  opacity: 1;
  transform: translateY(0);
  transition-property: opacity, transform;
  transition-duration: 300ms;
}

.slide-enter {
  position: absolute;
  transform: translateX(100%);
  opacity: 0;
}

.slide-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 500ms ease-out;
}

.slide-exit {
  position: absolute;
  transform: translateX(0);
  opacity: 1;
}

.slide-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 500ms ease-out;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-enter,
.fade-enter-active {
  animation-name: fadeInDown;
  animation-duration: 300ms;
  animation-timing-function: ease;
  animation-fill-mode: both;
}

.invisible {
 opacity:0
}
/* ... existing styles ... */
